import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from "aws-amplify";

Amplify.configure({
    Auth: {
        mandatorySignIn: false,
        region: "eu-central-1",
        // userPoolId: "us-east-1_5BcHKr3vi",
        // identityPoolId: "us-east-1:82b08912-9272-462c-8880-5e1f09caab59",
        // userPoolWebClientId: "50cp6nml763fc1n9tlrs7j3q6i"
    },
    API: {
        endpoints: [
            {
                name: "tvmsg",
                endpoint: "https://zg539njmh4.execute-api.eu-central-1.amazonaws.com/msg",
                region: "eu-central-1"
            },
        ]
    }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
