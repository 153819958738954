import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Grid, MenuItem, Fab, Snackbar } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import MuiAlert from '@material-ui/lab/Alert';

import { getClientId, getMessages, updateMessages, getClientDetails } from './apiManager';
import dateformat from 'dateformat';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
        margin: 10
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    textField: {
        marginBottom: theme.spacing(3),
        minWidth: "100%"
    },

    multiline: {
        marginBottom: theme.spacing(3),
        width: "100%"
    },

    fab: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    mainButtons: {
        textAlign: "right",
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1)

    },

    recordButtons: {
        textAlign: "right",
    },

    weatherSection: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
    },
    title: {
        textAlign: "center",
        fontFamily: "Tahoma, Helvetica, sans-serif"
    },
    activeDevices: {
        textAlign: "center",
        marginBottom: theme.spacing(2),
    },
    activeDevicesTitle: {

        marginBottom: theme.spacing(2),
        fontWeight: "bold"
    }
}));

var clientIdent = null;
//var clientCode = '6b2a05b2';
//var clientCode = '99a9dd3b';
var clientCode = (window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
var alertMessage = "";
var alertSeverity = "";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SimpleCard() {
    const classes = useStyles();
    const [showWeather, setShowWeather] = React.useState(-1);
    const [clientName, setClientName] = React.useState('');
    const [activeDevices, setActiveDevices] = React.useState('');
    var [messages, setMessages] = React.useState([]);
    const [showAlert, setShowAlert] = React.useState(false);
    const handleFieldChange = (value, i, field) => {
        console.log(value, i);
        var allMessages = [];
        for (var msg of messages) {
            allMessages.push(msg);
        }
        allMessages[i][field] = value;
        setMessages(allMessages);

    };

    const reloadData = (event) => {
        getMessages(clientIdent, afterGettingMessages);
    }
    const handleAdd = (event) => {
        console.log("add!");
        var allMessages = [];
        for (var msg of messages) {
            allMessages.push(msg);
        }
        var startDate = new Date();
        var endDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7);
        allMessages.unshift(
            {
                text: "",
                startDate: startDate,
                endDate: endDate,
                formattedStartDate: dateformat(startDate, "yyyy-mm-dd$HH:MM").replace('$', 'T'),
                formattedEndDate: dateformat(endDate, "yyyy-mm-dd$HH:MM").replace('$', 'T'),
                period: 30,
                image: ""
            }
        );
        setMessages(allMessages);
    }


    const handleRemove = (event, index) => {
        console.log("Remove", index);
        var allMessages = [];
        var i = 0;
        for (var msg of messages) {
            if (i != index) {
                allMessages.push(msg);
            }
            ++i;
        }
        setMessages(allMessages);
    }

    const handleSave = (event, index) => {
        var allMessages = [];
        for (var msg of messages) {
            if (!msg.management) {
                msg.startDate = Date.parse(msg.formattedStartDate);
                msg.endDate = Date.parse(msg.formattedEndDate);
                allMessages.push(msg);
            }
        }
        var messageToUpdate = {
            clientId: clientIdent,
            messages: allMessages,
        };

        if (showWeather > -1) {
            messageToUpdate.showWeather = showWeather;
        }
        console.log(allMessages);
        updateMessages(messageToUpdate, (response) => {
            if (typeof (response.status) != "undefined" && response.status == "success") {
                alertMessage = "Update successful / העדכון בוצע בהצלחה"
                alertSeverity = "success";
                reloadData();
            }
            else {
                alertMessage = "Update failed / שגיאה בעדכון";
                alertSeverity = "error";
            }
            setShowAlert(true);
            console.log(response);
        });
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    };

    function getMessageList() {
        var i = 0;
        var messageElements = [];
        for (var message of messages) {
            const current = i;
            messageElements.push(
                <Card className={classes.root} variant="elevation" elevation={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {messages[current].management ? (
                                <div className={classes.recordButtons}>
                                    Manager message / הודעת מנהל
                                </div>
                            ) : (
                                    <div className={classes.recordButtons}>
                                        <Fab color="primary" aria-label="add" size="small" className={classes.fab} onClick={e => handleRemove(e.target.value, current)}>
                                            <RemoveIcon />
                                        </Fab>
                                    </div>
                                )}
                        </Grid>
                        <Grid item xs={12}>
                            <div dir="rtl">
                                <TextField
                                    disabled={messages[current].management}
                                    id="standard-multiline-flexible"
                                    label="Text / טקסט"
                                    multiline
                                    rowsMax={20}
                                    value={messages[current].text}
                                    onChange={e => handleFieldChange(e.target.value, current, 'text')}
                                    className={classes.multiline}
                                    variant="outlined"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                disabled={messages[current].management}
                                id="startTime"
                                label="Start / התחלה"
                                type="datetime-local"
                                value={messages[current].formattedStartDate}
                                onChange={e => handleFieldChange(e.target.value, current, 'formattedStartDate')}
                                className={classes.textField}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                disabled={messages[current].management}
                                id="endTime"
                                label="End / סיום"
                                type="datetime-local"
                                value={messages[current].formattedEndDate}
                                onChange={e => handleFieldChange(e.target.value, current, 'formattedEndDate')}
                                className={classes.textField}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                disabled={messages[current].management}
                                id="outlined-select-currency-native"
                                select
                                label="Period in seconds / משך בשניות"
                                className={classes.textField}
                                value={messages[current].period}
                                onChange={e => handleFieldChange(e.target.value, current, 'period')}
                                variant="outlined">
                                <MenuItem key={10} value={10}>10</MenuItem>
                                <MenuItem key={20} value={20}>20</MenuItem>
                                <MenuItem key={30} value={30}>30</MenuItem>
                                <MenuItem key={60} value={60}>60</MenuItem>
                                <MenuItem key={120} value={120}>120</MenuItem>
                                <MenuItem key={180} value={180}>180</MenuItem>

                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                disabled={messages[current].management}
                                id="outlined-select-currency-native"
                                label="Image URL (beta) / (לינק לתמונה (בטא"
                                className={classes.textField}
                                value={messages[current].image}
                                onChange={e => handleFieldChange(e.target.value, current, 'image')}
                                variant="outlined" />
                        </Grid>
                    </Grid>
                </Card >
            );
            ++i;
        }

        return messageElements;
    }
    function afterGettingMessages(allMessages) {
        for (var message of allMessages.messages) {
            message.formattedStartDate = dateformat(new Date(message.startDate), "yyyy-mm-dd$HH:MM").replace('$', 'T');
            message.formattedEndDate = dateformat(new Date(message.endDate), "yyyy-mm-dd$HH:MM").replace('$', 'T');
            console.log(message.formattedStartDate);
        }
        setShowWeather(allMessages.showWeather);
        setMessages(allMessages.messages);
        console.log("done");
    }
    function afterClient(clientId) {
        if (typeof (clientId.clientId) != "undefined") {
            clientIdent = clientId.clientId;
            getClientDetails(clientIdent, (clientData) => {
                setClientName(clientData.name);
                var devices = [];
                if (typeof (clientData.devices) != "undefined") {
                    for (var deviceName of Object.keys(clientData.devices)) {
                        if (new Date().getTime() - clientData.devices[deviceName] < 10 * 60 * 1000) {
                            devices.push(<div>{deviceName.replace(",", " - ")}</div>);
                        }
                    }

                }
                if (devices.length == 0) {
                    devices.push(<div>No data / אין נתונים</div>);
                }
                setActiveDevices(devices);

                getMessages(clientIdent, afterGettingMessages);
            })
        }
    }

    if (clientIdent == null) {
        getClientId(clientCode, afterClient);
    }

    return (
        <div>
            <h3 className={classes.title}>
                Messages updater / עדכון הודעות
            </h3>
            <h4 className={classes.title}>
                {clientName}
            </h4>
            <div>
                <div className={classes.mainButtons}>
                    <Fab color="primary" aria-label="add" size="small" className={classes.fab} onClick={reloadData}>
                        <RefreshIcon />
                    </Fab>
                    <Fab color="primary" aria-label="add" size="small" className={classes.fab} onClick={handleSave}>
                        <SaveIcon />
                    </Fab>
                    <Fab color="primary" aria-label="add" size="small" className={classes.fab} onClick={handleAdd}>
                        <AddIcon />
                    </Fab>
                </div>

                {getMessageList()}
            </div>
            {typeof (showWeather) == "undefined" ? "" : (
                <div className={classes.weatherSection}>
                    <TextField
                        select
                        label="Weather screen / מסך מזג אוויר"
                        className={classes.textField}
                        value={showWeather}
                        onChange={e => setShowWeather(e.target.value)}
                        variant="outlined">
                        <MenuItem key={0} value={0}>Don't show / אל תציג</MenuItem>
                        <MenuItem key={20} value={20}>Show for 20 Seconds / הצג ל-20 שניות</MenuItem>
                        <MenuItem key={30} value={30}>Show for 30 Seconds / הצג ל-30 שניות</MenuItem>
                        <MenuItem key={60} value={60}>Show for 60 Seconds / הצג ל-60 שניות</MenuItem>

                    </TextField>
                </div>
            )}
            <div className={classes.activeDevices}>
                <div className={classes.activeDevicesTitle}>Active devices / מכשירים פעילים</div>
                {activeDevices}
            </div>
            <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertSeverity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}