import { API } from "aws-amplify";

export function getClientId(clientCode, callback) {
    const clientData = {
        "id": clientCode,
    };
    var requestParams =
    {
        body: clientData,
        headers: {
            "Content-Type": "application/json"
        }
    }
    API.post("tvmsg", "/ident", requestParams).then(function (response) {
        callback(response);
    })
        .catch(function (error) {
            // handle error
            console.log(error);
            callback(error);
        })
        .finally(function () {
            // always executed
        });
}

export function getMessages(clientId, callback) {
    const clientData = {
        "id": clientId,
    };
    var requestParams =
    {
        body: clientData,
        headers: {
            "Content-Type": "application/json"
        }
    }
    API.post("tvmsg", "/messages/get", requestParams).then(function (response) {
        callback(response);
    })
        .catch(function (error) {
            // handle error
            callback(error);
            console.log(error);
        })
        .finally(function () {
            // always executed
        });
}

export function getClientDetails(clientId, callback) {
    const clientData = {
        "id": clientId,
    };
    var requestParams =
    {
        body: clientData,
        headers: {
            "Content-Type": "application/json"
        }
    }
    API.post("tvmsg", "/config/client", requestParams).then(function (response) {
        callback(response);
    })
        .catch(function (error) {
            // handle error
            callback(error);
            console.log(error);
        })
        .finally(function () {
            // always executed
        });
}

export function updateMessages(messages, callback) {
    const clientData = {
        "id": messages.clientId,
        "replaceAll": true,
        "messages": messages.messages,
        "showWeather": messages.showWeather
    };
    var requestParams =
    {
        body: clientData,
        headers: {
            "Content-Type": "application/json"
        }
    }
    API.put("tvmsg", "/messages/add", requestParams).then(function (response) {
        callback(response);
    })
        .catch(function (error) {
            // handle error
            console.log(error);
            callback(error);
        })
        .finally(function () {
            // always executed
        });
}